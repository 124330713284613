import React from "react"
import { graphql, navigate } from "gatsby"

export default class extends React.Component {

  componentDidMount() {

    let search = ``
    if (typeof window !== `undefined`) {
      const urlParams = new URLSearchParams(window.location.search)
      if(urlParams.get('shape')) search = `&shape=${urlParams.get('shape')}`
    }

    const { campaign } = this.props.data
    navigate(`/screens/${campaign.screenLink.slug}?campaign=${campaign.slug}${search}`)
    
  }

  render() {
    return <></>
  }
}

export const CampaignQuery = graphql`
  query CampaignQuery($slug: String!) {
    campaign: contentfulCampaign(slug: { eq: $slug }) {
      slug
      screenLink {
        slug
      }
    }
  }
`
